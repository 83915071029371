import { html, render, nothing } from "lit-html";
import { IDetailedCartInfo, IDetailedCartInfoForUi } from "../interfaces/detailed-cart-info.js";
import { OrderDiscountProgressBar } from "./order-discount-progress-bar.js";
import { FreeShippingProgressBar } from "./free-shipping-progress-bar.js";
import { SiteCartController } from "../site-cart-controller.js";
import { Locale } from "../../../common/interfaces/locate.js";
import { determineTranslation } from "../../../utils/language.js";
import { cartEventSubscriber } from "../../../common/pubsub/cart-event-pub-sub.js";
import { CartSidebarOrderLines } from "./cat-sidebar-v1-parts/cart-sidebar-order-lines.js";
import { CartSidebarTotalSummary } from "./cat-sidebar-v1-parts/cart-sidebar-total-summary.js";
//The Vipps checkout button was not defined by BA+Desinger to be used in sitecart sidebar! Don't add it here!
export class CartSidebarV1 extends HTMLElement {
    constructor() {
        super();

        this._toggledById = this.getAttribute('toggledBy');

        if (!this._toggledById) {
            return;
        }

        this._toggledBy = document.querySelector(this._toggledById);

        if (!this._toggledBy) {
            throw Error('Can not find element which controls CmsSiteCardSidebar');
        }

        this._toggledBy.addEventListener('click', this.toggle.bind(this));
    }

    /* #region Public */
    public close() : void  {
        this.setAttribute('hidden', '');
        document.body.style.overflow = 'auto';
    }

    public open() : void  {
        this.removeAttribute("hidden");
        document.body.style.overflow = 'hidden';
    }

    public toggle() : void {
        if (this._isOpened) {
            this.close();
        } else {
            this.open();
        }
    }
    /* #endregion */

    /* #region Translations */
    private translations: ITranslationSet = {
        [Locale.En]:{
            heading: "Shopping cart",
            shoppingCartIsEmpty: "Shopping cart is empty!",
            btnRemoveAll: "Remove all",
            btnCheckout: "Go to cart",
        },
        [Locale.No]: {
            heading: "Min handlekurv",
            shoppingCartIsEmpty: "Handlekurven er tom!",
            btnRemoveAll: "Tøm handlekurv",
            btnCheckout: "Gå til kassen",
        }
    };
    private labels = determineTranslation(this.translations) as ISiteCartSidebarLabels;
    /* #endregion */

    /* #region Markup */
    private mainTemplate = () => html`
        <div class="sidebar">${this.sidebarTemplate()}</div>
        <div class="backdrop" @click=${(e:MouseEvent) => this.close()}></div>
    `;

    private sidebarTemplate = () => html`
        <header>
            <div class="d-flex justify-content-between align-items-center">
                <h2>${this.labels.heading}</h2>
                <button type="button" @click=${(e:MouseEvent) => this.close()} class="btn close" title="${this.labels.btnRemoveAll}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <path id="x" d="M4.835,4.835a1.143,1.143,0,0,1,1.618,0L12.5,10.884l6.047-6.049a1.144,1.144,0,0,1,1.618,1.618L14.115,12.5l6.049,6.047a1.144,1.144,0,0,1-1.618,1.618L12.5,14.115,6.453,20.164a1.144,1.144,0,1,1-1.618-1.618L10.884,12.5,4.835,6.453a1.143,1.143,0,0,1,0-1.618Z" transform="translate(-4.499 -4.499)" fill="#222226"/>
                    </svg>
                </button>
            </div>
            ${this._siteCartInfo.products.length > 0 ? html`
                <div class="remove-all-container">
                    <button type="button" @click=${this._clearCart.bind(this)} class="btn btn-link remove-all ${CssClasses.FontSizeSmall}">${this.labels.btnRemoveAll}</button>
                </div>
            ` : nothing }
        </header>
            ${this._siteCartInfo.products.length > 0 ? this._orderLinesTable : this.emptyMessageTemplate() }
        <footer>
            ${(window.globalSettings && window.globalSettings.freeShippingMessageSettings) ? this._freeShippingProgressBar : nothing }
            ${this._orderDiscountProgressBar}
            ${this._siteCartInfo.products.length > 0 ? this._totalSummary : nothing}
            <div class="checkout-buttons">
                <a href="${window.cartSettings.detailedCartPageUrl}" class="btn btn-go-to-detailed-cart btn-primary ${this._getDisabledState()}">${this.labels.btnCheckout}</a>
            </div>
        </footer>
    `;

    private emptyMessageTemplate = () => html`
        <div class="empty-cart-message">${this.labels.shoppingCartIsEmpty}</div>
    `;
    /* #endregion */

    /* #region Private */
    private _toggledById?: string;
    private _toggledBy?: HTMLElement;
    private _isOpened: boolean = false;
    private _siteCartController = new SiteCartController();
    private _siteCartInfo: IDetailedCartInfoForUi;
    private _orderLinesTable = new CartSidebarOrderLines();
    private _totalSummary = new CartSidebarTotalSummary();
    private _freeShippingProgressBar = new FreeShippingProgressBar();
    private _orderDiscountProgressBar = new OrderDiscountProgressBar();

    private _getDisabledState() {
        return this._siteCartInfo.products.length > 0 ? '' : 'disabled';
    }

    private _clearCart(){
        this._siteCartController.clearCart();
    }

    private _updateView() {
        render(this.mainTemplate(), this);
    }

    private async _onSiteCartChanged(topic: string, data: IDetailedCartInfo){
        this._siteCartInfo = data;
        this._freeShippingProgressBar.setData(data.freeShippingInfo);
        this._updateView();
    }
    /* #endregion */

    public connectedCallback() {
        if(this.isConnected){
            cartEventSubscriber.onSiteCartChanged(this._onSiteCartChanged.bind(this));
        }
    }

    disconnectedCallback() : void {
        if (this._toggledBy) {
            this._toggledBy.removeEventListener('click', this.toggle.bind(this));
        }
    }
}

customElements.define('cart-sidebar-v1', CartSidebarV1);

/* #region Local interfaces */
interface ISiteCartSidebarLabels{
    heading: string;
    shoppingCartIsEmpty: string;
    btnRemoveAll: string;
    btnCheckout: string;
}

interface ITranslationSet {
    [key:string]: ISiteCartSidebarLabels
}

enum CssClasses {
    FontSizeSmall = 'fs-sm',
}
/* #endregion */